import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import ImageArabic from "../../assets/images/logo.png";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./style.css";
import { Button } from "../../components/Button";
import DepartmentForm from "../../components/DepartmentDetailForm/DepartmentForm";
import SimpleMap from "../../components/Map/MapComponent";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import qs from "qs";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IDepartmentDetail {
  locale?: string;
  setDepartmentDetailPage?: any;
  isEdit?: boolean;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedDepartment: any;
  setRefetch: any;
  isLoading: boolean;
  setIsLoading: any;
}

const DepartmentDetail: React.FC<IDepartmentDetail> = ({
  locale,
  setDepartmentDetailPage,
  isEdit,
  setIsEdit,
  isEditDetail,
  setIsEditDetail,
  selectedDepartment,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  // console.log("selectedDepartment", selectedDepartment);
  const intl = useIntl();
  const [departmentDetail, setDepartmentDetail] = useState(
    isEdit
      ? selectedDepartment
      : {
          username: "",
          token: "",
          name: "",
          name_ar: "",
          dept_code: "",
          description: "",
          description_ar: "",
          geo_lat: "",
          geo_lng: "",
          mobile_no: "",
          email: "",
          is_agriculture: "",
          is_active: "",
          id: "",
          employee_id: "",
          coordinates: "",
        }
  );
  // console.log("departmentDetail", departmentDetail);

  const handleAddUpdateDepartment = () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("username", "admin");
    data.append("token", JSON.stringify(sessionStorage.getItem("token")));
    data.append("name", departmentDetail?.name);
    data.append("name_ar", departmentDetail?.name_ar);
    data.append("dept_code", departmentDetail?.id || 0);
    data.append("description", departmentDetail?.description);
    data.append("description_ar", departmentDetail?.description_ar);
    data.append("mobile_no", departmentDetail?.mobile_no);
    data.append("email", departmentDetail?.email);
    data.append("is_agriculture", departmentDetail?.is_agriculture);
    data.append("image", departmentDetail?.image);
    data.append("geo_lat", departmentDetail?.geo_lat);
    data.append("geo_lng", departmentDetail?.geo_lng);
    data.append("is_active", departmentDetail?.is_active === true ? "1" : "0");
    data.append("id", departmentDetail?.id);
    data.append("employee_id", departmentDetail?.employee_id || 0);
    data.append("coordinates", departmentDetail?.coordinates);

    // data.forEach((value, key) => {
    //   console.log(key, value);
    // });

    axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/Departments/addEdit`, data)
      .then((response: any) => {
        // console.log("response",response)
        setDepartmentDetail((prev: any) => ({
          ...prev,
          id: response?.data?.ref,
        }));
        setIsEdit(true);
        setDepartmentDetailPage(false);
        setRefetch((prev: any) => !prev);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const [uploadedImage, setUploadedImage] = useState<any>(
    departmentDetail?.image ? departmentDetail?.image : ""
  );

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles?.forEach((file: File) => {
        if (file) {
          setDepartmentDetail((prev: any) => ({
            ...prev,
            image: file,
          }));
          const reader = new FileReader();
          reader.onloadend = () => {
            // After reading the file, set the uploaded image in the state
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [selectedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleDeactivateDepartment = () => {
    Swal.fire({
      title: intl.formatMessage({ id: "are_you_sure" }),
      showDenyButton: true,
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      denyButtonText: intl.formatMessage({ id: "no" }),
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result: any) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      if (result.isConfirmed && userId) {
        let data = qs.stringify({
          department_id: selectedDepartment?.id,
          status: selectedDepartment?.is_active ? "0" : "1",
          user_id: userId,
        });

        axios
          .post(
            `${process.env.REACT_APP_PUBLIC_URL}/Departments/updateStatus`,
            data
          )
          .then((res) => {
            // console.log("res", res);
            setDepartmentDetail((prev: any) => ({
              ...prev,
              is_active: !prev.is_active,
            }));
            setRefetch((prev: boolean) => !prev);
            setDepartmentDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };
  const handleDeleteDepartment = () => {
    Swal.fire({
      title: intl.formatMessage({ id: "are_you_sure" }),
      showDenyButton: true,
      confirmButtonText: intl.formatMessage({ id: "yes" }),
      denyButtonText: intl.formatMessage({ id: "no" }),
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result: any) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      if (result.isConfirmed && userId) {
        axios
          .post(
            `${process.env.REACT_APP_PUBLIC_URL}/Departments/delete/${selectedDepartment?.id}?user_id=${userId}`
          )
          .then((res) => {
            // console.log(res);
            setRefetch((prev: boolean) => !prev);
            setDepartmentDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setDepartmentDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p
                className="back-p"
                onClick={() => setDepartmentDetailPage(false)}
              >
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">
                <FormattedMessage id="add_new_department" />
              </p>
            </Col>
          )}
          {/* <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={departmentDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (departmentDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateDepartment();
                  }
                }}
              />
            ) : (
              ""
            )}
            {
              !isEdit ? (
                <Button
                  title={
                    !isEditDetail
                      ? "Add"
                      : isEdit
                      ? "Edit Details"
                      : "Save changes"
                  }
                  className={"save-changes-btn"}
                  onClick={() => {
                    handleAddUpdateDepartment();
                  }}
                />
              ) : (
                ""
              )
              // (
              //   <Button
              //     title={<FormattedMessage id="reset_password" />}
              //     className={"reset-password-btn"}
              //     onClick={() => {}}
              //   />
              // )
            }
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setDepartmentDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={departmentDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() =>
                  departmentDetail?.is_active
                    ? handleDeactivateDepartment()
                    : handleDeleteDepartment()
                }
              />
            )}
          </Col> */}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {/* Edit Details Button */}
            {isEdit && (
              <Button
                title={<FormattedMessage id="edit_details" />}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  setIsEdit(false);
                  setIsEditDetail(true);
                }}
              />
            )}

            {/* Save Changes Button */}
            {!isEdit && (
              <Button
                title={
                  isEditDetail ? (
                    <FormattedMessage id="save_changes" />
                  ) : (
                    <FormattedMessage id="add" />
                  )
                }
                className="save-changes-btn"
                onClick={() => {
                  handleAddUpdateDepartment();
                }}
              />
            )}
            {/* Active Button */}
            {departmentDetail?.is_active === false && (
              <Button
                title={<FormattedMessage id="active" />}
                className="save-changes-btn"
                hoverClassName=""
                onClick={() => {
                  if (departmentDetail?.is_active === false) {
                    handleDeactivateDepartment();
                  }
                }}
              />
            )}

            {/* Cancel Button */}
            {/* {!isEdit ? (
              <Button
                title="Cancel"
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setDepartmentDetailPage(false);
                }}
              />
            ) : null} */}

            {/* Deactivate/Delete Button */}
            {/* <Button
              title={departmentDetail?.is_active ? "Deactivate" : "Delete"}
              className="deactivate-btn"
              onClick={() => {
                departmentDetail?.is_active
                  ? handleDeactivateDepartment()
                  : handleDeleteDepartment();
              }}
            /> */}
            {!isEdit && (
              <Button
                title={<FormattedMessage id="cancel" />}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setDepartmentDetailPage(false);
                }}
              />
            )}

            {departmentDetail?.is_active && (
              <Button
                title={<FormattedMessage id="deactivate" />}
                className={"deactivate-btn"}
                onClick={() => {
                  if (departmentDetail?.is_active) {
                    handleDeactivateDepartment();
                  }
                }}
              />
            )}

            {(isEdit || isEditDetail) && (
              <Button
                title={<FormattedMessage id="delete" />}
                className={"deactivate-btn"}
                onClick={() => handleDeleteDepartment()}
              />
            )}
          </Col>

          {/* <hr /> */}
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">
              <FormattedMessage id="department_details" />
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={3} lg={2}>
            <div
              style={{
                width: "100%",
                // background: "rgba(0, 0, 0, 0.50)",
                height: "170px",
                borderRadius: "8px",
              }}
            >
              {departmentDetail?.image ? (
                !isEdit && isEditDetail ? (
                  <div
                    style={{
                      background: `url(${uploadedImage})`,
                      height: "inherit",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                    // {...getInputProps()}
                  >
                    <input
                      type="file"
                      id="file-input"
                      data-testid="file-input"
                      style={{ width: "100%", height: "100%" }}
                      {...getInputProps()}
                    />
                    <label htmlFor="file-input">
                      <span
                        style={{
                          paddingRight: "10px",
                          paddingBottom: "10px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        <FormattedMessage id="edit" />
                      </span>
                    </label>
                  </div>
                ) : (
                  <div>
                    <img className="image-div" src={uploadedImage} alt="Logo" />
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: "100%",

                    height: "170px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(0, 0, 0, 0.50)",
                      height: "inherit",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    {...getRootProps()}
                  >
                    <input
                      type="file"
                      id="file-input"
                      data-testid="file-input"
                      {...getInputProps()}
                    />

                    <FiUpload color="white" size={30} />
                    <label htmlFor="file-input"></label>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} md={9} lg={10} className="inputs-col">
            <DepartmentForm
              isEdit={isEdit}
              departmentDetail={departmentDetail}
              setDepartmentDetail={setDepartmentDetail}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "60px" }}>
          <Col sm={12} md={6} lg={12}>
            <div>
              <p className="employee-title">
                <FormattedMessage id="department_description" />
              </p>
              <ReactQuill
                value={departmentDetail?.description}
                readOnly={isEdit && departmentDetail?.is_active}
                onChange={(value) => {
                  setDepartmentDetail((prev: any) => ({
                    ...prev,
                    description: value,
                  }));
                }}
                className="quill-editor"
                modules={{
                  toolbar: [
                    // container:"border-2",
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    // [{ direction: "rtl" }],
                    ["clean"], // remove formatting button
                  ],
                }}
              />
              {/* <input
                disabled={isEdit && departmentDetail?.is_active}
                type="text"
                className="emloyee-input"
                placeholder={"Enter Description"}
                value={departmentDetail?.description}
                onChange={(e) =>
                  setDepartmentDetail((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                style={{ width: "100%" }}
              /> */}
              {/* <p >write...</p> */}
            </div>
            <div style={{ marginTop: "20px" }}>
              <p className="employee-title">
                <FormattedMessage id="department_description_ar" />
              </p>
              <ReactQuill
                value={departmentDetail?.description_ar}
                readOnly={isEdit && departmentDetail?.is_active}
                onChange={(value) => {
                  setDepartmentDetail((prev: any) => ({
                    ...prev,
                    description_ar: value,
                  }));
                }}
                className="quill-editor rtl-quill-editor"
                modules={{
                  toolbar: [
                    // container:"border-2",
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    // [{ direction: "rtl" }],
                    ["clean"], // remove formatting button
                  ],
                }}
              />
              <style>{`
      .rtl-quill-editor .ql-editor {
        direction: rtl;
        text-align: right;
      }
    `}</style>
              {/* <input
                disabled={isEdit && departmentDetail?.is_active}
                type="text"
                className="emloyee-input"
                placeholder={"وصف إنتر"}
                value={departmentDetail?.description_ar}
                onChange={(e) =>
                  setDepartmentDetail((prev: any) => ({
                    ...prev,
                    description_ar: e.target.value,
                  }))
                }
                style={{ width: "100%", textAlign: "right" }}
              /> */}
              {/* <p >write...</p> */}
            </div>
            <div style={{ marginTop: "20px" }}>
              <p className="employee-title">
                <FormattedMessage id="department_location" />
              </p>
              <SimpleMap
                departmentDetail={departmentDetail}
                setDepartmentDetail={setDepartmentDetail}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};

export default DepartmentDetail;
